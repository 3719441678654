import { cn } from "@/lib/tailwind-utils";
import React from "react";

const Wrapper = ({ children, className }) => {
  return (
    <div
      className={cn("w-full max-w-[1450px] px-5 md:px-10 mx-auto", className)}
    >
      {children}
    </div>
  );
};

export default Wrapper;
