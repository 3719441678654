import { create } from "zustand";

const useEventStore = create((set) => {
  const today = new Date();
  const halloweenStart = new Date("2024-10-22");
  const halloweenEnd = new Date("2024-10-31");
  const isHalloween = today >= halloweenStart && today <= halloweenEnd;

  return {
    isHalloween,
  };
});

export default useEventStore;