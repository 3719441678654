import React from "react";
import Wrapper from "./Wrapper";
import Link from "next/link";
import { useRouter } from "next/router";

const HeaderBack = (Props) => {
  const router = useRouter();
  const handleBack = (e) => {
    e.preventDefault();
    router.back();
  };
  return (
    <>
      <header
        className={`w-full bg-brand-uniwhite flex items-center justify-between z-20 sticky top-0 transition-transform duration-300`}
      >
        {/* <Link href="#" onClick={(e) => handleBack(e)}>
          <div className="flex text-[20px] px-[15px] my-[20px] font-extrabold">
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 14.0013H10.14L13.77 9.64125C13.9397 9.43704 14.0214 9.17375 13.997 8.90933C13.9726 8.6449 13.8442 8.40099 13.64 8.23125C13.4358 8.06151 13.1725 7.97985 12.9081 8.00423C12.6437 8.02861 12.3997 8.15704 12.23 8.36125L7.23 14.3613C7.19636 14.409 7.16628 14.4591 7.14 14.5113C7.14 14.5613 7.14 14.5913 7.07 14.6413C7.02467 14.7559 7.00094 14.878 7 15.0013C7.00094 15.1245 7.02467 15.2466 7.07 15.3613C7.07 15.4113 7.07 15.4413 7.14 15.4913C7.16628 15.5434 7.19636 15.5935 7.23 15.6413L12.23 21.6413C12.324 21.7541 12.4418 21.8449 12.5748 21.9071C12.7079 21.9694 12.8531 22.0015 13 22.0013C13.2337 22.0017 13.4601 21.9203 13.64 21.7713C13.7413 21.6873 13.825 21.5842 13.8863 21.4679C13.9477 21.3515 13.9855 21.2242 13.9975 21.0932C14.0096 20.9622 13.9957 20.8302 13.9567 20.7046C13.9176 20.579 13.8542 20.4623 13.77 20.3613L10.14 16.0013H22C22.2652 16.0013 22.5196 15.8959 22.7071 15.7084C22.8946 15.5208 23 15.2665 23 15.0013C23 14.736 22.8946 14.4817 22.7071 14.2941C22.5196 14.1066 22.2652 14.0013 22 14.0013Z"
                  fill="#101010"
                />
              </svg>
            </div>
            <div className="ml-[10px]">
              
            </div>
          </div>
        </Link> */}

        <div className="flex items-center w-full gap-2.5 avenir-black font-semibold text-lg py-4 px-4">
          <svg
            onClick={handleBack}
            width="30"
            height="30"
            className=""
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 14.0013H10.14L13.77 9.64125C13.9397 9.43704 14.0214 9.17375 13.997 8.90933C13.9726 8.6449 13.8442 8.40099 13.64 8.23125C13.4358 8.06151 13.1725 7.97985 12.9081 8.00423C12.6437 8.02861 12.3997 8.15704 12.23 8.36125L7.23 14.3613C7.19636 14.409 7.16628 14.4591 7.14 14.5113C7.14 14.5613 7.14 14.5913 7.07 14.6413C7.02467 14.7559 7.00094 14.878 7 15.0013C7.00094 15.1245 7.02467 15.2466 7.07 15.3613C7.07 15.4113 7.07 15.4413 7.14 15.4913C7.16628 15.5434 7.19636 15.5935 7.23 15.6413L12.23 21.6413C12.324 21.7541 12.4418 21.8449 12.5748 21.9071C12.7079 21.9694 12.8531 22.0015 13 22.0013C13.2337 22.0017 13.4601 21.9203 13.64 21.7713C13.7413 21.6873 13.825 21.5842 13.8863 21.4679C13.9477 21.3515 13.9855 21.2242 13.9975 21.0932C14.0096 20.9622 13.9957 20.8302 13.9567 20.7046C13.9176 20.579 13.8542 20.4623 13.77 20.3613L10.14 16.0013H22C22.2652 16.0013 22.5196 15.8959 22.7071 15.7084C22.8946 15.5208 23 15.2665 23 15.0013C23 14.736 22.8946 14.4817 22.7071 14.2941C22.5196 14.1066 22.2652 14.0013 22 14.0013Z"
              fill="#101010"
            />
          </svg>

          <span className="leading-none">
            {Props.pathname === "/cart"
              ? "Shopping Cart"
              : Props.pathname === "/checkoutshipping"
              ? "Add Address & Pay"
              : Props.pathname === "/checkoutreview"
              ? "Checkout Review"
              : ""}
          </span>
        </div>
      </header>
    </>
  );
};

export default HeaderBack;
