import { CONST_IP_DETAILS } from "@/utils/constants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie';

// Define the initial state for the new slice
const initialState = {
  location: {
    city: "",
    country: "",
    flag: "",
    countryRegion: "",
    region: "",
    latitude: "",
    longitude: "",
  },
  loading: false,
  error: null,
};

// Create an async thunk for the API call
export const fetchLocation = createAsyncThunk(
  "location/fetchLocation",
  async () => {
    const response = await axios.get(CONST_IP_DETAILS);
    const data = response.data;

    // Save location details in a cookie
    Cookies.set('location', JSON.stringify(data), { expires: 7 }); 

    return data;
  }
);

// Create the slice
export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    clearLocation: (state) => {
      state.location = initialState.location;
      state.loading = false;
      state.error = null;
      Cookies.remove('location'); // Remove the cookie when clearing location
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.location = action.payload;
        state.loading = false;
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export const { clearLocation } = locationSlice.actions;
export default locationSlice.reducer;
