import { cn } from "@/lib/tailwind-utils";
import Image from "next/image";
import { usePathname } from "next/navigation";
import React, { useState, useEffect, useCallback } from "react";
import { HiOutlineArrowUp } from "react-icons/hi"; // Assuming you have react-icons installed

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  const pathname = usePathname();

  const isEventType = useCallback(
    () => pathname?.includes("/events/"),
    [pathname]
  );
  const deadpoolEvent = useCallback(
    () => pathname?.includes("/events/deadpool"),
    [pathname]
  );

  // is ai page
  const isAiPage = () => pathname?.includes("/ai/products");
  // console.log({ isAiPage: isAiPage() })

  const customTopIcon =
    isEventType() && deadpoolEvent() ? "/events/deadpool/backToTop.png" : null;

  // Function to handle scrolling
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show or hide the button based on scroll position
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Attach event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // console.log({
  //   customTopIcon,
  //   pathname,
  //   isEventType: isEventType(),
  //   deadpoolEvent: deadpoolEvent(),
  // });

  return (
    <>
      {customTopIcon !== null ? (
        <>
          <Image
                unoptimized
            src={customTopIcon}
            alt="Back to top"
            width={50}
            height={50}
            className={cn(
              "fixed bottom-[2rem] right-[2rem] z-[9] p-2 transition duration-300 ease-in-out block opacity-0 pointer-events-none",
              isVisible
                ? "opacity-100 pointer-events-auto cursor-pointer"
                : "opacity-0"
            )}
            onClick={scrollToTop}
          />
        </>
      ) : (
        <button
          className={cn(
            "opacity-0 fixed bottom-8 right-8 z-[9] bg-gray-800 text-brand-uniwhite p-2 rounded-full shadow-md transition duration-300 ease-in-out pointer-events-none",
            isVisible ? "opacity-100 pointer-events-auto" : "opacity-0",
            isAiPage() && "bottom-20 md:bottom-8"
          )}
          onClick={scrollToTop}
        >
          <HiOutlineArrowUp className="text-[20px]" />
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
