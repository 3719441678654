import { useEffect, useState } from "react";

const mediaQueries = {
  xs: "(max-width: 480px)",
  sm: "(max-width: 640px)",
  md: "(max-width: 768px)",
  lg: "(max-width: 1024px)",
  xl: "(max-width: 1280px)",
  "2xl": "(max-width: 1536px)",
};

/**
 * Custom hook that returns a boolean value indicating whether the current viewport matches the specified breakpoint.
 *
 * @param {string} breakpoint - The breakpoint to match against. Must be a key from the `mediaQueries` object.
 * @returns {boolean} - `true` if the current viewport matches the specified breakpoint, `false` otherwise.
 */
const useMediaQuery = (breakpoint) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueries[breakpoint]);
    setMatches(mediaQueryList.matches);

    const handleChange = (event) => {
      setMatches(event.matches);
    };

    mediaQueryList.addEventListener("change", handleChange);

    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, [breakpoint]);

  return matches;
};

export default useMediaQuery;
