import React from "react";
import Wrapper from "./Wrapper";

const FooterContent = (props) => {
  return (
    <>
      {props.content && (
        <div className="bg-white">
          <div className="xs:border-t-[1px] sm:border-t-[1px] md:border-t-[3px] lg:border-t-[3px] border-brand-nebula"></div>
          <Wrapper>
            <div className="xs:pt-[35px] sm:pt-[35px] md:pt-[80px] lg:pt-[80px] xs:pb-[120px] sm:pb-[120px] md:pb-[130px] lg:pb-[130px] text-brand-charcoal/90">
              <article
                className="footer-content prose max-w-none prose-h2:font-bold prose-h2:uppercase text-brand-charcoal/90 prose-strong:text-brand-charcoal"
                dangerouslySetInnerHTML={{ __html: props.content }}
              ></article>
            </div>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default FooterContent;
