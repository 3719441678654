import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    addressItems: [],
  },
  reducers: {
    addAddress: (state, action) => {
      state.addressItems = [];
      //   state.addressItems = state.addressItems.filter(
      //     (p) => p.info.type !== action.payload.type
      //   );

      state.addressItems.push({
        ...action.payload,
        type: action.payload.info.type,
      });
    },
    updateAddress: (state, action) => {
      state.addressItems = state.addressItems.map((p) => {
        if (p.type === action.payload.type) {
          return { ...p, [action.payload.key]: action.payload.val };
        }
        return p;
      });
    },
    removeAddress: (state, action) => {
      state.addressItems = state.addressItems.filter(
        (p) => p.type !== action.payload.type
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { addAddress, updateAddress, removeAddress } =
  addressSlice.actions;

export default addressSlice.reducer;
