import algoliasearch from "algoliasearch/lite";

// Connect and authenticate with your Algolia app using environment variables
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

const ALGOLIA_SEARCH_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX;

export { searchClient, ALGOLIA_SEARCH_INDEX };
