import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  name: "N/A",
  phone: "N/A",
  email: "N/A",
};

// Create the slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { name, phone, email } = action.payload;
      state.name = name;
      state.phone = phone;
      state.email = email;

      // Save user details in a cookie
      Cookies.set("user", JSON.stringify({ name, phone, email }), { expires: 15 });
    },
    clearUser: (state) => {
      state.name = "N/A";
      state.phone = "N/A";
      state.email = "N/A";

      // Remove the cookie
      Cookies.remove("user");
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
