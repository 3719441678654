import clsx from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Utility function to generate classNames conditionally
 * using clsx and tailwind-merge.
 *
 * @param {...any} inputs - Class names, style objects, conditional values etc.
 * @returns {string} Merged classnames
 *
 * @example
 * const isActive = true
 * cn('text-lg', { 'font-bold': isActive })
 * // - - - OR - - -
 * cn('text-lg', isActive ? 'font-bold' : 'font-normal')
 * // equivalent to: 'text-lg font-bold' or 'text-lg'
 * // depending on the value of `isActive`
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
