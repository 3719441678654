import React, { useState, useEffect } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { CONST_MENU } from "@/utils/constants";
import { cn } from "@/lib/tailwind-utils";
const WebmenuTrendingProduct = dynamic(() =>
  import("@/components/WebmenuTrendingProduct")
);
const WebmenuProduct = dynamic(() => import("@/components/WebmenuProduct"));
const WebMenu2 = ({ onHoverDisableSearch, className, menuItems }) => {
  const [seletedmenuItem, setSeletedMenuItem] = useState(0);
  const [CategoryItems, setCategoryItems] = useState([]);

  useEffect(() => {
    const unique = [...new Set(menuItems.map((item) => item.category))];
    setCategoryItems(unique);
  }, [menuItems]);

  // if menu is open, you can't scroll the body
  useEffect(() => {
    if (seletedmenuItem === 0) {
      document.body.style.overflow = "inherit";
      document.body.style.position = "inherit";
      document.body.style.inlineSize = "inherit";
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.position = "sticky"; // don't use fixed because it will scroll to top
      document.body.style.inlineSize = "100%";
      document.body.style.top = "0";
    }
  }, [seletedmenuItem]);

  return (
    <>
      <div className={cn("flex justify-center text-brand-charcoal", className)}>
        {CategoryItems.map((top_item, i) => {
          return (
            <div key={i}>
              <button
                // data-active={"true"}
                data-active={seletedmenuItem === i + 1}
                className={cn(
                  "text-base md:min-h-[80px] md:text-sm mx-[15px] md:mx-[5px] --lg:mx-[25px] avenir-book",
                  seletedmenuItem === i + 1
                    ? "text-brand-nebula font-medium"
                    : ""
                )}
                onMouseEnter={() => {
                  setSeletedMenuItem(i + 1);
                  // onHoverDisableSearch();
                }}
                onMouseLeave={() => {
                  setSeletedMenuItem(0);
                  // onHoverDisableSearch();
                }}
              >
                {top_item}
              </button>

              <div
                className={cn(
                  `megamenu overflow-hidden transition-all duration-500 top-[78px] block bg-white w-full z-[99] absolute left-0`,
                  "overflow-y-auto scrollbar-thin",
                  seletedmenuItem === i + 1
                    ? // seletedmenuItem === i + 1
                      "max-h-[600px]  border-y border-brand-charcoal/30"
                    : "max-h-0"
                )}
                onMouseEnter={() => {
                  setSeletedMenuItem(i + 1);
                  // onHoverDisableSearch();
                }}
                onMouseLeave={async () => {
                  setSeletedMenuItem(0);
                  // 200 ms delay to prevent flickering
                  await new Promise((resolve) => setTimeout(resolve, 200));
                  // onHoverDisableSearch();
                }}
              >
                <div className="flex w-full max-w-[790px] mx-auto mt-[30px] pb-[30px]">
                  <div className="w-[25%]">
                    <p
                      className={cn(
                        `level1 text-brand-dark-gray capitalize text-xs `,
                        seletedmenuItem === i + 1
                          ? "label-fadeInDown"
                          : "label-fadeInUp"
                      )}
                    >
                      Shop
                    </p>
                    {
                      <WebmenuTrendingProduct
                        title={i + 1}
                        data={menuItems}
                        category={top_item}
                        seletedmenuItem={seletedmenuItem}
                      ></WebmenuTrendingProduct>
                    }
                  </div>
                  <WebmenuProduct
                    data={menuItems.filter(
                      (item) => item.category === top_item
                    )}
                    title={i + 1}
                    seletedmenuItem={seletedmenuItem}
                  ></WebmenuProduct>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default WebMenu2;
