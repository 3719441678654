
export const schema = [
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Macmerise",
    "url": "https://macmerise.com",
    "logo": "https://s3.ap-south-1.amazonaws.com/macmerise.com/logo/image.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91 7279999888",
      "contactType": "Customer Service",
      "areaServed": ["IN", "US", "GB", "AU", "Worldwide"],
      "availableLanguage": ["English"]
    },
    "sameAs": [
      "https://www.instagram.com/macmerise/",
      "https://www.facebook.com/macmerise",
      "https://x.com/macmerise",
      "https://www.youtube.com/channel/UC1eyGta27u5LVxiaD4BBlIg"
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "141, Adarsh Indl. Estate, Sahar Road, Chakala, Andheri - East",
      "addressLocality": "Mumbai",
      "postalCode": "400059",
      "addressCountry": "IN"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Macmerise",
    "url": "https://macmerise.com",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://macmerise.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://macmerise.com",
    "name": "Macmerise | Buy Perfect gifts Online to celebrate moments",
    "about": {
      "@type": "Thing",
      "name": "Buy Phone Cases, Accessories, Apparel, Electronics, and Home Decor Online"
    },
    "description": "Explore Macmerise for AI-designed mobile accessories and officially licensed products, including unique skins, cases, and more. Elevate your lifestyle with exclusive designs and top-quality products today!",
    "mainEntityOfPage": "https://macmerise.com",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://macmerise.com"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Deals",
          "item": "https://macmerise.com/deals"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Blog",
          "item": "https://macmerise.com/blog"
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "Product Guide",
          "item": "https://macmerise.com/productguide"
        }
      ]
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "url": "https://macmerise.com/category/apparel-and-accessories",
        "name": "Apparel and Accessories"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "url": "https://macmerise.com/category/electronics-and-accessories",
        "name": "Electronics and Accessories"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "url": "https://macmerise.com/category/home-and-living",
        "name": "Home and Living"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "url": "https://macmerise.com/category/games-and-stationery",
        "name": "Games and Stationery"
      },
      {
        "@type": "ListItem",
        "position": 5,
        "url": "https://macmerise.com/category/bags-and-wallets",
        "name": "Bags and Wallets"
      }
    ]
  }
]


export const faqSchema = {
  "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is Macmerise?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Macmerise is a leading brand offering personalized accessories, apparel, electronics, home decor, and more."
        }
      },
      {
        "@type": "Question",
        "name": "How can I contact Macmerise?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can contact Macmerise via WhatsApp or call at +91 7279999888, or email at cs@macmerise.com."
        }
      },
      {
        "@type": "Question",
        "name": "Where can I find Macmerise's deals?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can find all ongoing deals and promotions at https://macmerise.com/deals."
        }
      },
      {
        "@type": "Question",
        "name": "How do I place my order?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To place your order, follow these steps: Browse through our wide catalogue of Gadget Accessories until you find something you like, choose the relevant device (in case of Phone Cases select the Phone Model; likewise for other products) and mention the quantity required. Finally click on the 'Add to Cart' button (you are eligible to add multiple products to your cart). In ‘My cart’, you can either choose to continue shopping or checkout for your existing order. To proceed, you can either log in through your existing Facebook/Gmail/Twitter account or simply continue as a ‘New User’ and create one. You can also checkout as a guest by providing either your phone number or email address. Once this is all set, confirm your shipping address, select the desired payment method, and place your order. As soon as the order is placed, you will receive an Order Number with your Order Details on the registered email ID / Mobile Number."
        }
      },
      {
        "@type": "Question",
        "name": "How do I confirm my order?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "For COD orders, you will receive a text message on the registered number to verify your order. You have to go through the details of your order in the message and give a missed call on the suggested number to confirm your order. However, if you have an afterthought and decide to change your mind and cancel the order :( then you will have to call our customer care number and break the Bad news to us, and our team member will do the needful. In case you wish to modify your order, you will have to call our Customer Care number or email us at cs@macmerise.com or support@macmerise.com, and our team will get it done for you. For Prepaid orders, the order is directly approved and verified for production. No Order Confirmation required."
        }
      },
      {
        "@type": "Question",
        "name": "How will I get the confirmation of my Order being placed successfully?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "An email / sms will be sent with your order information confirming your order and another email when your order is shipped out. For COD orders, you will also receive a text message to confirm the order. Once you give the missed call on the mentioned number, the order is considered confirmed. Post Order confirmation, you just have to relax and wait for your awesome product/s to arrive."
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take for delivery?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When you order from our website, we ensure that the order gets processed within 24 hours, and we try to deliver the products to you within 1-3 working days in major metro cities. However, the duration of the delivery can take 6-8 working days depending on your delivery location."
        }
      },
      {
        "@type": "Question",
        "name": "Can I have my order delivered on a particular day?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We strive to deliver products purchased from Macmerise in excellent condition and in the fastest time possible. However, we can't commit to the exact date of shipment. We are working closely with our Logistics team to ensure an exact Delivery Date in the future. Currently, we can only provide this service in Mumbai."
        }
      },
      {
        "@type": "Question",
        "name": "How much do I have to pay for delivery?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anywhere in India, we offer free shipping on all prepaid orders over INR 398. However, we charge COD charges of INR 50 for every order. For orders less than INR 398, a shipping charge of INR 40 will be charged. International shipping rates depend on the country and will be automatically calculated at checkout."
        }
      },
      {
        "@type": "Question",
        "name": "How can I track my order?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Your order status is updated to you via emails at every step. An email is sent to you after the order is shipped that contains the tracking number and the service provider. If you are having trouble reading the emails or if you haven't received any updates, please get in touch with the Macmerise Team immediately. Give us a call on +91 7279999888 or drop us an email at cs@macmerise.com or support@macmerise.com, and we will make sure your query is attended to."
        }
      },
      {
        "@type": "Question",
        "name": "Can I return the order to the courier agent and take my cash back from him?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We apologize, but we do not have the facility of returning the product to the courier agent and taking the cash back from him. In case you are not satisfied with the product, please contact our team on +91 7279999888 or drop us an email at cs@macmerise.com or support@macmerise.com."
        }
      },
      {
        "@type": "Question",
        "name": "Do you deliver pan India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. We strive to deliver our products to pan India. Though the COD (Cash on Delivery) option is available in 12,000 odd pincodes in India, with the prepaid option we can deliver anywhere in India. The core intention is to make Macmerise products available to the end customer residing in any part of the country, thus dissolving the geographical boundaries."
        }
      },
      {
        "@type": "Question",
        "name": "Does Macmerise deliver products outside India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, we do deliver products outside India. However, the international courier charges are additional. The standard shipping takes anywhere between 10-15 days, whereas express international shipping takes up to 7 days."
        }
      },
      {
        "@type": "Question",
        "name": "My items haven't arrived yet. What can I do?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Please allow up to 8 business days for delivery from the day you received the order confirmation. Your order status is updated to you via emails at every step. An email is sent to you after the order is shipped that contains the tracking number and the service provider. The order status would provide detailed information on your package. If, for any reason, our shipping partner cannot reach you, we will provide you with the instructions to collect the product(s) directly from our shipping partner (this happens very rarely though). If you are having trouble reading the emails or if you haven't received any updates, please get in touch with the Macmerise Team. Give us a call on +91 7279999888 or drop us an email at cs@macmerise.com or support@macmerise.com."
        }
      },
      {
        "@type": "Question",
        "name": "Can I cancel my order?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can cancel your order until it goes into processing. Once we receive your order, we begin printing your product specifically for you. Therefore, cancellations are not possible once the order has entered the processing stage. If you wish to cancel, please send us an email at cs@macmerise.com or WhatsApp us on +917279999888."
        }
      },
      {
        "@type": "Question",
        "name": "Please explain the product return procedure.",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you wish to return any product, please inform the Macmerise team at cs@macmerise.com or support@macmerise.com, or you can call us at +91-7279999888. Once a return request is authorized and approved from our end, please ship the product to us. Please ship the product in a transit-worthy packaging, safely to our office at the following address:\n\nMacmerise,\n141, Adarsh Indl. Estate,\nNext to Cigarette Factory,\nSahar Road, Chakala,\nAndheri - E, Mumbai-400099.\n\nWe will replace or refund the product or the product cost within 2-4 working days of receiving it. We will refund the amount in terms of store credit and will also refund the shipping costs borne by you (up to Rs. 100) against a valid invoice copy."
        }
      },
      {
        "@type": "Question",
        "name": "How do I initiate a return process?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Please contact the Macmerise team to initiate the return process. You can contact us by either sending an email at cs@macmerise.com or support@macmerise.com, or calling us at +91 7279999888. We believe in the “Fix it” Policy. If for any reason you are unhappy with the product, we will try our best to resolve the same for you. In case of any valid complaint, we are happy to replace the product as well. However, if you still wish to return the product, you are entitled to do so."
        }
      },
      {
        "@type": "Question",
        "name": "Who pays for the shipping if I send the item back?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Return shipping cost is the responsibility of the customer. However, we do reimburse the return shipping charges upon providing a valid bill from the courier company (up to Rs. 100) in the form of store credit. In case you need to send the product yourself, kindly ensure that you use a trackable shipping method. For all return shipments, please send an email to cs@macmerise.com or support@macmerise.com with your order details and the tracking ID. Please call the Macmerise Team on +91 7279999888 for further queries."
        }
      },
      {
        "@type": "Question",
        "name": "What is the return notice order?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We will gladly accept any unused product in its original packaging. We generally refund money to you within 2-4 working days after the receipt of the product. However, you will get the refund only in the form of store credit. The duration to return the product is 30 days from the order date."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any conditions placed on returning a product?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There are no severe terms and conditions to return the product. The returned product should be in its original packaging and in the same condition as you received it. That’s it!"
        }
      },
      {
        "@type": "Question",
        "name": "What can lead to cancellation of my order from your end?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When our logistic partners do not have serviceability of Cash on Delivery in the mentioned pin code, we have to cancel the order. But we can always send orders anywhere if they are paid online via credit or debit card. This, however, happens very rarely."
        }
      },
      {
        "@type": "Question",
        "name": "What if the packaging of the product is damaged upon delivery?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you discover that the product packaging has been opened or is damaged, please do not accept the package and immediately notify our team. We will offer a full refund or send a replacement product."
        }
      },
      {
        "@type": "Question",
        "name": "What if the product has defects?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you have received a defective product or are not happy with it, a replacement will be sent over to you as soon as possible for free."
        }
      },
      {
        "@type": "Question",
        "name": "How and when will Macmerise refund the money?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If, due to any circumstances, Macmerise has to refund the money, it will happen within 2-4 working days after receiving your product. The refund will happen via store credit."
        }
      },
      {
        "@type": "Question",
        "name": "What payment types do you accept?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We support the following payment options: Cash on Delivery (COD) (Only in India), Credit card, Debit card, Wallets, UPI, Paypal, and Net Banking."
        }
      },
      {
        "@type": "Question",
        "name": "How does Cash on Delivery (COD) work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We provide you the facility of making cash payments at the time of delivery of the confirmed order. The payment will be required to be made to the delivery boy while receiving the product. Please ensure that the product packaging has not been tampered with, before making the payment and receiving the order. Kindly note you will need to make the payment before opening the product packaging."
        }
      },
      {
        "@type": "Question",
        "name": "I tried placing my order using my Debit Card/Credit Card/Net Banking. The order was not successful but my money got deducted. What really happened with my money?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Once you make payment via our payment gateway, the money gets deducted from the bank and then is received by our payment gateway. Sometimes, due to connection issues, the money does get deducted from the bank but does not reach our payment gateway. In such cases, you do not need to panic. Within 5-7 days (depending on your bank), the money comes back to your bank account. If you do not receive that money, you can contact us, and we will get in touch with the required parties and give you a clear picture."
        }
      }
    ]
}