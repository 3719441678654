import { createSlice } from "@reduxjs/toolkit";

export const shippingSlice = createSlice({
  name: "shipping_option",
  initialState: {
    shipping: "",
  },
  reducers: {
    addShippingOption: (state, action) => {
     
      state.shipping = action.payload.info;
    },
    removeShippingOption: (state, action) => {
    
      state.shipping = null;
    }
  },
});

export const { addShippingOption, removeShippingOption } = shippingSlice.actions;

export default shippingSlice.reducer;
