import React from "react";
import Link from "next/link";
import { cn } from "@/lib/tailwind-utils";
const MobileStaticLinks = ({
  link,
  title,
  handleCloseMobileMenu,
  className,
}) => {
  return (
    <li className="mb-[5px]">
      <Link
        className={cn(
          "w-full px-4 text-brand-charcoal capitalize text-base mb-0 leading-normal",
          className
        )}
        href={link}
        onClick={handleCloseMobileMenu}
      >
        {title}
      </Link>
    </li>
  );
};
export default MobileStaticLinks;
