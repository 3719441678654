import { createSlice } from "@reduxjs/toolkit";

export const discountSlice = createSlice({
  name: "promo",
  initialState: {
    discount: null,
  },
  reducers: {
    addDiscount: (state, action) => {
    
      state.discount = action.payload.discount;
    },
    removeDiscount: (state, action) => {
      state.discount = null;
    },
  },
});

export const { addDiscount, removeDiscount } = discountSlice.actions;

export default discountSlice.reducer;
