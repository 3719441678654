import { createSlice } from "@reduxjs/toolkit";

export const giftCardSlice = createSlice({
  name: "giftcard",
  initialState: {
    wallet: null,
  },
  reducers: {
    addGiftCard: (state, action) => {
    
      state.wallet = action.payload.wallet;
    },
    removeGiftCard: (state, action) => {
      state.wallet = null;
    },
  },
});

export const { addGiftCard, removeGiftCard } = giftCardSlice.actions;

export default giftCardSlice.reducer;
