import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const item = state.cartItems.find(
        (p) =>
          !p.productinfo?.is_customise_item && p.productinfo.product_variance_id ===
          action.payload.productinfo.product_variance_id
      );
      
      if (item) {
        if(item.quantity)
          item.quantity++;
        else
          item.quantity=1;
       
        
        item.total_price = action.payload.productinfo.price * item.quantity;
      } else {
       

        state.cartItems.push({ ...action.payload, quantity: (action.payload.quantity||1) });
      }
    },
    updateCart: (state, action) => {
     
      state.cartItems = state.cartItems.map((p) => {
        if (
          !p.productinfo?.is_customise_item && p.productinfo.product_variance_id ===
          action.payload.product_variance_id
        ) {
          if (action.payload.key === "quantity") {
           
            p.quantity = action.payload.val;
            p.total_price = p.productinfo.price * action.payload.val;
          }
          return { ...p, [action.payload.key]: action.payload.val };
        }
        return p;
      });
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (p) =>
          !p.productinfo?.is_customise_item && p.productinfo.product_variance_id !==
          action.payload.product_variance_id
      );
    },
    clearCart: (state, action) => {
      state.cartItems = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, updateCart, removeFromCart,clearCart } = cartSlice.actions;

export default cartSlice.reducer;
