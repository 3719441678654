import { createSlice } from "@reduxjs/toolkit";

export const orderTypeSlice = createSlice({
  name: "orderType",
  initialState: {
    order_type: "Online Payments",
  },
  reducers: {
    addOrderType: (state, action) => {
      state.order_type = action.payload.info;
    },
    removeOrderType: (state, action) => {
      state.order_type = null;
    },
  },
});

export const { addOrderType, removeOrderType } = orderTypeSlice.actions;

export default orderTypeSlice.reducer;
