import { closeModal, openModal } from "@/store/modalSlice";
import { useSelector, useDispatch } from "react-redux";

export function useModal() {
  const state = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const handleOpenModal = ({ view, customSize, size }) => {
    dispatch(openModal({ view, customSize, size }));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return {
    ...state,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  };
}
